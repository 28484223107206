import React, { useEffect, useState } from "react";
import "./style/derivatorPages.css";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { HeaderComponent } from "../../components/HeaderComponent";
import ButtonGroupStepperSaleComponent from "./components/Mobile/ButtonGroupStepperSaleComponent";
import { CotizadorDesktopComponent } from "./components/Desktop/CotizadorDesktopComponent";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function CotizadorPage() {
  const { deal_id } = useParams();
  const { isEdit } = useSelector((state) => state.quoteReducer);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`https://infoauto-348420.rj.r.appspot.com/deal/${deal_id}/car`)
      .then((resp) => {
        let lengthOfObject = Object.keys(resp.data.car).length;
        if (isEdit) {
        } else if (lengthOfObject !== 0) {
          navigate(`/resumen/${deal_id}`);
        }
      })
      .catch((e) => {
        console.log("ERROR", e);
      });
  }, []);

  const [isTitleShow, setIsTitleShow] = useState(true);
  const [expanded, setExpanded] = useState(false);
  let breakPointMobile = useMediaQuery("(min-width: 430px)");

  return (
    <div style={{ height: breakPointMobile ? "94vh" : "95vh" }}>
      <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
      <div className={`cotizador-desktop ${expanded ? "blur-background" : ""}`}>
        <CotizadorDesktopComponent />
      </div>
      <div className={`cotizador-mobile ${expanded ? "blur-background" : ""}`}>
        <div style={{ height: "100%" }}>
          <ButtonGroupStepperSaleComponent
            isCotizador={true}
            isTitleShow={isTitleShow}
            setIsTitleShow={setIsTitleShow}
          />
        </div>
      </div>
    </div>
  );
}
