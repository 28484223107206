import "../styles/components/baseRadioButton.css";
import { Form } from "react-bootstrap";
import axios from "axios";

export default function BaseRadioButtonGroup({
  isGnc,
  setIsGnc,
  kms,
  version_id,
  setShowModalStopper,
  setShowDropdownQuote,
  setShowDropdownKms,
}) {
  const handleOptionChange = (e) => {
    const intValue = parseInt(kms.replace(/\./g, ""), 10); // Convierte a entero y elimina separadores de miles
    axios
      .get(
        `https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/is_stopper?idAuta=${version_id}&kms=${intValue}&gnc=${
          e.target.value === "No" ? false : true
        }`
      )
      .then((resp) => {
        if (resp.data.stopper) {
          setShowModalStopper(true);
        } else {
          setIsGnc(e.target.value);
          setShowDropdownKms(false);
          setShowDropdownQuote(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div>
        <p className="radio-button-title">¿Tiene o tuvo GNC?</p>
        <div className="radio-button-container">
          <Form.Check
            className="radio-button-input"
            type="radio"
            label="Sí"
            value="Si"
            checked={isGnc === "Si"}
            onChange={handleOptionChange}
          />

          <Form.Check
            className="radio-button-input"
            type="radio"
            label="No"
            value="No"
            checked={isGnc === "No"}
            onChange={handleOptionChange}
          />
        </div>
      </div>
    </>
  );
}
