import React, { useEffect } from "react";
import SalePrice from "../images/sale-price.svg";
import { useNavigate, useParams } from "react-router-dom";
import { HeaderComponent } from "../../../components/HeaderComponent";
import "../style/sucess.css";
import ArrowDown from "../images/arrow-down.svg";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getDeal } from "../service/fetchDataCar";
import { isEdit } from "../../../actions/quote";

export const SuccessCOmponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { car } = useSelector((state) => state.quoteReducer);
  const { deal_id } = useParams();

  useEffect(() => {
    dispatch(getDeal(deal_id, navigate));
  }, []);

  const navigateToCotizador = () => {
    navigate(`/${deal_id}`);
    dispatch(isEdit(true));
  };

  let isMobile = useMediaQuery("(max-width: 500px)");

  function formatNumberWithCommas(numberOrString) {
    let number = parseFloat(numberOrString);

    if (isNaN(number)) {
      return "Invalid input";
    }
    const isFloat = number % 1 !== 0;

    const formattedNumber = isFloat
      ? number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedNumber;
  }

  return (
    <>
      <HeaderComponent />
      <div
        className="container-success d-flex align-items-center justify-content-start flex-column"
        style={{ padding: "10px" }}
      >
        <div className="d-flex align-items-center justify-content-between flex-column mb-5">
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <p className="title-success mt-2 mt-md-5">Datos del auto.</p>
              <img
                src={ArrowDown}
                alt="arrow-down"
                style={{
                  marginTop: "-10px",
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center container-card-cotizacion mt-3">
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ width: "100%" }}
              >
                <img
                  src={SalePrice}
                  alt="auta-button"
                  className="card-icon-cotizacion"
                />
                <div className="d-flex align-items-start justify-content-center flex-column ms-2 ms-md-3">
                  <p className="car-model-cotizacion">
                    {typeof car?.brand === "string"
                      ? car.brand
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())
                      : car?.brand}{" "}
                    {typeof car?.model === "string"
                      ? car.model
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())
                      : car?.model}
                  </p>
                  <p className="car-version-cotizacion">
                    {" "}
                    {typeof car?.version === "string"
                      ? car.version
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())
                      : car?.version}
                  </p>
                  <p className="car-year-kms-cotizacion mt-2">
                    {car?.year} | {formatNumberWithCommas(car?.kms)} kms
                  </p>
                </div>
              </div>
              <div className="mt-2 mt-md-3 container-btn-success">
                <button
                  className="purple-button-inspect me-2"
                  onClick={navigateToCotizador}
                >
                  Editar auto
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
