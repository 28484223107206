import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { SuccessCOmponent } from "../modules/Cotizador/components/SuccessCOmponent";
import CotizadorPage from "../modules/Cotizador/CotizadorPage";

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/:deal_id" element={<CotizadorPage />} />
        <Route path="/resumen/:deal_id" element={<SuccessCOmponent />} />
        <Route
          path="*"
          element={
            <div>
              <h1>Ingrese un deal_id en la url.</h1>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}
