import axios from "axios";
import { getQuote, setQuote } from "../../../actions/quote";
import Swal from "sweetalert2";

export const sendCotizacion = async (
  brand,
  brand_id,
  model,
  model_id,
  version,
  version_id,
  year,
  gnc,
  kms,
  telefono,
  nombre,
  setIsLoading,
  setCurrentView,
  setPriceQuote,
  navigate,
  dispatch
) => {
  const data = {
    brand: brand,
    brand_id: brand_id,
    model: model,
    model_id: model_id,
    version: version,
    version_id: version_id,
    year: year,
    gnc: gnc,
    kms: kms,
    name: nombre,
    cel: telefono,
    source: "landing_auta",
  };

  axios
    .post(`https://infoauto-348420.rj.r.appspot.com/landing_auta`, data)
    .then((resp) => {
      setPriceQuote(resp.data.price);
      setCurrentView("step3");
      setIsLoading(false);
      dispatch(
        setQuote({
          price: resp?.data?.price,
          deal_id: resp?.data?.deal_id,
          car: {
            brand: brand,
            model: model,
            version: version,
            year: year,
            kms: kms,
          },
        })
      );
      navigate("/success");
    })
    .catch((e) => {
      console.log(e);
    });
};

export const sendEditCar = async (
  brand,
  brand_id,
  model,
  model_id,
  version,
  version_id,
  year,
  gnc,
  kms,
  deal_id,
  valueTemplate,
  navigate,
  setLoadingSend
) => {
  const intValue = parseInt(kms.replace(/\./g, ""), 10);

  const data = {
    brand: brand,
    brand_id: brand_id,
    model: model,
    model_id: model_id,
    version: version,
    version_id: version_id,
    year: year,
    gnc: gnc === "Si" ? true : false,
    kms: intValue,
    deal_id: deal_id,
    send_template: valueTemplate === "Si" ? true : false,
    source: "landing_auta",
  };

  axios
    .post(
      `https://infoauto-348420.rj.r.appspot.com/deal/${deal_id}/carAuta`,
      data
    )
    .then((resp) => {
      setLoadingSend(false);
      navigate(`/resumen/${deal_id}`);
      Swal.fire("Editar auto", "El auto fue editado correctamente.", "success");
    })
    .catch((e) => {
      setLoadingSend(false);
      Swal.fire("Editar auto", e.response.data.Desc, "error");
      // const error = JSON.parse(e.request.response);
      // dispatch(startLoading(error.message, e.request.status));
      console.log(e);
    });
};

export const activeServer = () => {
  axios
    .get(
      "https://pricing-auta-back-dot-admin-prod-368214.rj.r.appspot.com/quoter/check_app"
    )
    .then((resp) => {
      console.log(resp);
    })
    .catch((e) => {
      console.log(e);
    });
};
