import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import BaseModalComponet from "../../../../components/BaseModalComponet";
import ManualQuoteModalComponent from "../../../../components/ManualQuoteModalComponent";
import ModalMinKmsComponent from "../../../../components/ModalMinKmsComponent";
import { getBrands, getModels, getVersion } from "../../service/fetchDataCar";
import { TitleHomeWithIcon } from "../../../../components/TitleHomeWithIcon";
import "../../style/style.css";
import { StepperCotizaComponent } from "./StepperCotizaComponent";
import { colors } from "../../../../styles/colors";
import { ModalStopperComponent } from "../ModalStopperComponent";
import { sendEditCar } from "../../service/cotizar";
import { useNavigate, useParams } from "react-router-dom";

export const CotizadorDesktopComponent = () => {
  const [currentView, setCurrentView] = useState("step2");
  const [selectedItem, setSelectedItem] = useState(null);
  const [number, setNumber] = useState("");
  const [isGnc, setIsGnc] = useState("");
  const [sendQuote, setSendQuote] = useState("");
  const [isRenewManualModalOpen, setRenewManualModalOpen] = useState(false);
  const [showDropdownKms, setShowDropdownKms] = useState(false);
  const [showModalStopper, setShowModalStopper] = useState(false);
  const { deal_id } = useParams();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setIsGnc("");
    const inputValue = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
    if (inputValue === "") {
      setNumber(""); // Si inputValue está vacío, establece el estado como una cadena vacía
    } else {
      const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      setNumber(formattedNumber);
    }
  };

  const toggleRenewModal = () => {
    setRenewManualModalOpen(!isRenewManualModalOpen);
  };

  const [selectedOptions, setSelectedOptions] = useState({
    year: null,
    brand: null,
    model: null,
    version: null,
    kms: null,
  });

  const [dataBrands, setDataBrands] = useState();
  const [dataBrandsSearch, setDataBrandsSearch] = useState();
  const [dataModels, setDataModels] = useState();
  const [dataModelsSearch, setDataModelsSearch] = useState();
  const [dataVersions, setDataVersions] = useState();
  const [dataVersionsSearch, setDataVersionsSearch] = useState();
  const [loadingSend, setLoadingSend] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);

  const handleShowModalForm = () => {
    setLoadingSend(true);
    sendEditCar(
      selectedOptions?.brand?.name,
      selectedOptions?.brand?.id,
      selectedOptions?.model?.name,
      selectedOptions?.model?.id,
      selectedOptions?.version?.name,
      selectedOptions?.version?.id,
      selectedOptions?.year?.name,
      isGnc,
      number,
      deal_id,
      sendQuote,
      navigate,
      setLoadingSend
    );
  };

  useEffect(() => {
    getBrands(selectedOptions?.year?.name, setDataBrands, setDataBrandsSearch);
  }, [selectedOptions?.year]);

  useEffect(() => {
    getModels(
      selectedOptions?.brand?.name,
      selectedOptions?.year?.name,
      setDataModels,
      setDataModelsSearch
    );
  }, [selectedOptions?.brand]);

  useEffect(() => {
    getVersion(
      selectedOptions?.year?.name,
      selectedOptions?.brand?.name,
      selectedOptions?.model?.name,
      setDataVersions,
      setDataVersionsSearch
    );
  }, [selectedOptions?.model]);

  const handleOptionSelect = (option, value, id) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [option]: { id: id, name: value },
    }));
  };

  const handleItemClick = (selectedValue) => {
    setSelectedItem(selectedValue);
  };
  const isFormValid = isGnc !== "" && number !== "" && sendQuote !== "";

  return (
    <div className="container-cotizador">
      <>
        {currentView === "step2" ? (
          <div
            className="d-flex flex-column align-items-center justify-content-between"
            style={{ height: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <TitleHomeWithIcon
                title={"Editá el auto."}
                fontSize={"28px"}
                colorText={colors.purple.purple}
                colorIcon={colors.purple.purple}
              />
              <Container
                className="d-flex align-items-start justify-content-around mt-2"
                style={{ height: "450px", width: "1320px" }}
              >
                <StepperCotizaComponent
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                  handleItemClick={handleItemClick}
                  selectedItem={selectedItem}
                  dataBrandsSearch={dataBrandsSearch}
                  setDataBrands={setDataBrands}
                  dataBrands={dataBrands}
                  dataModelsSearch={dataModelsSearch}
                  setDataModels={setDataModels}
                  dataModels={dataModels}
                  dataVersionsSearch={dataVersionsSearch}
                  setDataVersions={setDataVersions}
                  dataVersions={dataVersions}
                  number={number}
                  handleInputChange={handleInputChange}
                  isGnc={isGnc}
                  setIsGnc={setIsGnc}
                  handleShowModalForm={handleShowModalForm}
                  isFormValid={isFormValid}
                  handleOptionSelect={handleOptionSelect}
                  showDropdownKms={showDropdownKms}
                  setShowDropdownKms={setShowDropdownKms}
                  setNumber={setNumber}
                  sendQuote={sendQuote}
                  setSendQuote={setSendQuote}
                  loadingSend={loadingSend}
                />
              </Container>
            </div>
            <BaseModalComponet
              isOpen={isRenewManualModalOpen}
              onClose={toggleRenewModal}
              size={"xs"}
            >
              <ManualQuoteModalComponent />
            </BaseModalComponet>
          </div>
        ) : null}
        <ModalStopperComponent
          showModalStopper={showModalStopper}
          setShowModalStopper={setShowModalStopper}
          setSelectedOptions={setSelectedOptions}
          setNumber={setNumber}
          setIsGnc={setIsGnc}
          setShowDropdownKms={setShowDropdownKms}
        />
        {showModal && (
          <ModalMinKmsComponent
            show={showModal}
            kms={number}
            setShow={setShowModal}
            setIsContinuing={setIsContinuing}
            setCurrentView={setCurrentView}
          />
        )}
      </>
    </div>
  );
};
